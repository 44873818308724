//react hooks
import React, { useState, useEffect } from "react";
import Categoris from "./Categoris"
//assets
import logo from "./assets/logo.svg";
import open from "./assets/menu.svg";

//componen
function Header(props) {
  //set states
  const [scroll, setScroll] = useState("35px");
  const [opacity, setOpacity] = useState(0);
  const [box, toggleBox] = useState(1);

  useEffect(() => {
    props.page === "singleProduct"
      ? setOpacity(1)
      : setOpacity(
          window.scrollY === 0
            ? 0
            : window.scrollY > 600
            ? 1
            : window.scrollY / 600
        );
    const scrollCheck = window.scrollY > 35 ? "0" : 35 - window.scrollY;
    setScroll(scrollCheck);
  }, []);

  //hendle header scrolling
  useEffect(() => {
    document.addEventListener("scroll", () => {
      props.page === "singleProduct"
        ? setOpacity(1)
        : setOpacity(
            window.scrollY === 0
              ? 0
              : window.scrollY > 600
              ? 1
              : window.scrollY / 600
          );
      const scrollCheck = window.scrollY > 35 ? "0" : 35 - window.scrollY;
      if (scrollCheck !== scroll) {
        setScroll(scrollCheck);
      }
    });
  });

  //remove user cookies on logging out
  function removeCookies() {
    document.cookie = "id=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  }
  //render
  return (
    <header onMouseLeave={() => toggleBox(1)} id="home" className="App-header">
      <div
        style={{
          marginBottom: props.page !== "home" ? "95px" : "",
        }}
        className="messege"
      >{
        props.page == "categoris"?
            <div style={{color: "#6f5cff"}}>{props.showMesseg}</div>
         :  <div className="grad-text "  >
              <span> לרגל ההשקה :</span>
              <a onClick={props.goToRegister} className="linklogin buttonn"> הרשמו חינם </a>
              <span>למועדון הלקוחות של בום ביי וקבלו הטבות</span>
            </div>   
        
      }

      </div>
      <div
        style={{
          top: scroll,
          background:
            "transparent linear-gradient(240deg, rgb(162, 8, 255, " +
            opacity +
            ") 0%, rgb(162, 8, 255, " +
            opacity +
            ") 100%) 0% 0% no-repeat padding-box",
        }}
        className="nev"
      >
        <div className="nev-menue">
          {props.width < 900 ? (
            <img
              src={open}
              onClick={() => props.toggleHamburger()}
              className="open-ham"
            ></img>
          ) : (
            ""
          )}
          {props.width > 900 && props.userData["ID"] ? (
            <span onMouseEnter={() => toggleBox(-1)}>
             {props.category
             ?<a className="user-img">{props.userData["data"]["name"][0]}</a>
             :<a className="user-img">{props.userData["name"]}</a>
            //  :<a className="user-img">{props.userData["data"]["name"][0]}</a>
             
             } 
              <a>{props.userData["data"]["name"]}</a>
            </span>
          ) : props.width > 900 ? (
            <a className="register button" onClick={props.goToRegister}>
              התחברות / הרשמה
            </a>
          ) : (
            ""
          )}
          {/* {
                props.showBooten == 'true' ?
                <a className="faq-link button " onClick={props.goToCategoris}> blackFriday</a>
                :<div></div>
              } */}
          {box > 0 ? (
            ""
          ) : (
            <div className="profile-box">
              <div className="user-data">
                <a className="user-img">{props.userData["data"]["name"][0]}</a>
                <a>{props.userData["data"]["name"]}</a>
                {/* <a>{props.userData["data"]["user_email"]}</a> */}
              </div>
              <a
                onClick={() => {
                  toggleBox(1);
                  props.removeUser();
                  removeCookies();
                }}
                className="log-out"
              >
                יציאה
              </a>
            </div>
          )}
          <a className="faq-link" href="#faq">
            שאלות נפוצות
          </a>
            {
                props.showBooten == 'true' ?
                <div style={{marginRight:"25px",fontWeight: "bold"}}>
                {/* <a className="faq-link" onClick={props.goToCategoris} >
                  {props.name_catgory}          
                </a> */}
          </div>
                :<div></div>
              }
          
          
        </div>
        <a
          className="logo-wrapper"
          onClick={() => {
            setOpacity(0);
            props.changePage();
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
        >
          <img src={logo} />
        </a>
      </div>
    </header>
  );
}

export default Header;

//assets
import img_a from "./product-imgs/product_a.svg";
import brand from "./product-imgs/brand.jpg";
import bell from "./assets/belln.svg";
import { LazyLoadImage } from 'react-lazy-load-image-component';

//states
import React, { useEffect, useState } from "react";

//component
function OffProduct(props) {
  //set state
  const [remined, setReminder] = useState(0);
  const [reminderUser, setReminderUser] = useState(0);
  const [userProdact, setUserProdact] = useState(0);
    const [useFuncrionnn , setUseFuncrionnn]= useState(false);

  //count produts in loop
  useEffect(async() => {
    props.countLoop();
  
    console.log(props.reminderUser,"props.reminderUserprops.reminderUser");
    if(props.reminderUser){
              const r =  await Promise.all(props.reminderUser.map(r =>{
              var ret =  r.replace('peoduct-','');
              if(props.productData.id == ret){
                setUserProdact(1)
              }
  }))}
  }, [reminderUser]);
  // console.log(props.productData,"props.productDataprops.productData");
  //handles remonder of coupon request
   function reminderHendler() {
    setReminder(1);
    fetch(
      "https://manage.boombuy.co.il/wp-json/react/v1/reminder?email=" +
        props.user["data"]["user_email"] +
        "&product=" +
        props.productData["id"]
    )
      .then((response) => {response.json()})
      .then((resData) => {
        console.log(resData,"+++++++++++++++++");
        setReminder(2);
      }).catch((e)=>{
        console.log(e,"88888888");
      })
      ;
  }

  //render
  return (
    <div>
      <div
        className="product off-product"
        style={{
          background:
            props.status === "future"
              ? "white"
              : "",
        }}
      >
        <div className="brand">
          {props.productData["brnad_logo"] ? (
            <img src={props.productData["brnad_logo"]} />
          ) : (
            ""
          )}
          {props.status === "past" && props.productData["brand"] ? (
            <h2>{props.productData["brand"]["name"]}</h2>
          ) : (
            ""
          )}
        </div>
        <h2
          className="product-title"
          style={{ color: props.status === "future" ? "" : "" }}
        >
          {props.productData["title"]}
        </h2>
        <div className="img-container">
          {props.productData["main_image"] ? (
            <LazyLoadImage
                className="product-img"
                // alt={image.alt}
                // height={image.height}
                src={props.productData["main_image"]} // use normal <img> attributes as props
                // width={image.width}
                 />
            // <img
            //   className="product-img"
            //   src={props.productData["main_image"]}
            // />
          ) : (
            ""
          )}
        </div>
        {props.status === "past" ? (
          <div className="price">
            {props.productData["static_sale_price"] ? (
              <h2>
                <span>₪</span>
                {props.productData["static_sale_price"]}
              </h2>
            ) : props.productData["static_price"] ? (
              <h2>
                <span>₪</span>
                {props.productData["static_price"]}
              </h2>
            ) : (
              ""
            )}
            {props.productData["static_price"] &&
            props.productData["static_sale_price"] ? (
              <h4>
                <span>₪</span>
                {props.productData["static_price"]}
              </h4>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
      </div>
      {props.status === "future" ? (
        <div
          onClick={() =>
            props.user["ID"] ? reminderHendler() : props.goToregister()
          }
          style={{
            background: (remined > 1 || userProdact >0) ? "#0ACCA5" : "#E6007E",
            color: (remined > 1 || userProdact >0) ? "white" : "white",
          }}
          className="remined action-wrap"
        >
          {remined === 0 && userProdact ===0? <img src={bell} style={{ color:"white"}} /> : ""}
          {remined !== 1 ? (
            <a>{(remined > 1 || userProdact >0) ? "התראה פעילה" : "קבלו התראה"}</a>
          ) : (
            <i className="grad-text fa fa-circle-o-notch fa-spin" style={{ color:"white"}}></i>
          )}
        </div>
      ) : (
        <div className="finish action-wrap">
          <a>בום הסתיים</a>
        </div>
      )}
    </div>
  );
}

export default OffProduct;

//react hooks
import React, { useState } from "react";
import Favicon from "react-favicon";

//assets
import logo from "./assets/logo-colored.svg";
import illustration from "./assets/cart-phon.svg";
import "font-awesome/css/font-awesome.min.css";
import gif from "./assets/load.gif";
import whatapp from "./assets/whatapp.png";
import ReactGA from 'react-ga';
//component
function SignIn(props) {
  //set state
  const [action, setAction] = useState("register");
  const [emailExist, setEmailExist] = useState(0);
  const [submited, setSubmited] = useState(0);
  const [terms, setTerms] = useState(1);
  const [male, setMale] = useState(1);
  const [female, setFemale] = useState(1);

  const [club, setClub] = useState(1);
  const [gender, serGender] = useState("");
  const [details, setDetails] = useState({
    name: { value: "", exist: 0 },
    email: { value: "", exist: 0, validate: 0 },
    password: { value: "", exist: 0, validate: 0 },
    phone: { value: "", exist: 0, validate: 0 },
    date: { value: "", exist: 0, validate: 0 },
    month: { value: "", exist: 0, validate: 0 },
    year: { value: "", exist: 0, validate: 0 },
    address: { value: "", exist: 0, validate: 0 },
    otp: { value: "", exist: 0 },
  });

  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let validate = re.test(String(email).toLowerCase());
    if (validate) {
      validateEmailExist(email);
    }
    return validate;
  }

  function validateEmailExist(email) {
    fetch(
      "https://manage.boombuy.co.il/wp-json/react/v1/users1?user_email=" +
        email,
      {
        method: "GET",
      }
    )
      .then((response) => response.json())
      .then((resData) => {
        setTimeout(function () {
          setEmailExist(resData ? 1 : 2);
        }, 250);
      });
  }

  function validateReg() {
    if (
      emailExist === 1 ||
      !details["email"]["validate"] ||
      !details["password"]["validate"] ||
      !details["name"]["exist"] ||
      terms === 1 ||
      (club === -1 && !details["phone"]["validate"])
    ) {
      return false;
    } else {
      return true;
    }
  }

  //register user and then log them in
  function register() {
    ReactGA.initialize('UA-189028073-1');

    ReactGA.event({
      category: 'lead',
      action: 'sing in'
    });
    const data = {};
    for(let detailName in details){
      data[detailName] = details[detailName].value;
    }
    if (!validateReg()) {
      setSubmited(1);
    } else {
      fetch(
        "https://manage.boombuy.co.il/wp-json/react/v1/user-test",
        {
          method: "POST",
          body: JSON.stringify(data)
        }
      )
        .then(function (response ) {
            response.json().then((json) => {
            if (response.status !== 200) {
              alert(json.error);
            }
            else if(json){

              login();
            }

         })
        }).catch((error) => {
          console.log(error);
          alert("התרחשה שגיאה, נסה שנית מאוחר יותר");
        });
        const dataremaketly = `${details["date"]["value"]}`+"-"+`${details["month"]["value"]}`+"-"+`${details["year"]["value"]}`
        const requestOptions = {
          method: 'POST',
          headers: {'Content-Type' : 'application/json'},
          body: JSON.stringify({
            "email" : details["email"]["value"],
            "sms_phone_number" : details["phone"]["value"],
            "sms_country_code" : "IL" ,
            "properties": {
              "city": details["address"]["value"],
              "birth_date": dataremaketly,
              "gender": gender
            }
          })
        };
        fetch('https://app.remarkety.com/api/v1/stores/E4lbD6Xn/contacts',requestOptions)

}
  }

  //log the user in
  function login() {
    props.askData();
    fetch(
      "https://manage.boombuy.co.il/wp-json/react/v1/users2/?username=" +
        details["email"]["value"] +
        "&password=" +
        details["password"]["value"],
      {
        method: "GET",
      }
    )
      .then((response) => response.json())
      .then((resData) => {
        if (resData["ID"]) {
          let now = new Date();
          now.setDate(now.getDate() + 7);
          setSubmited(0);
          props.setNewUser(resData);
          document.cookie =
            "user_id=" +
            resData["data"]["ID"] +
            "; expires=" +
            now.toUTCString() +
            ";";
        } else {
          setSubmited(2);
        }
        props.endLoad();
        return;
      });
  }

  function recover() {
    if (emailExist !== 1) {
      setSubmited(1);
    } else {
      fetch(
        "https://manage.boombuy.co.il/wp-json/bdpwr/v1/reset-password/?email=" +
          details["email"]["value"],
        {
          method: "POST",
        }
      )
        .then((response) => response.json())
        .then((resData) => {
          setAction("recover");
          setDetails((prevState) => {
            return {
              ...prevState,
              password: {
                value: "",
                exist: 0,
                validate: 0,
              },
            };
          });
          setSubmited(0);
        });
    }
  }

  function doRecover() {
    if (
      !details["password"]["validate"] ||
      !details["password"]["exist"] ||
      !details["otp"]["exist"] ||
      !details["password"]["exist"]
    ) {
      setSubmited(1);
    } else {
      fetch(
        "https://manage.boombuy.co.il//wp-json/bdpwr/v1/set-password/?email=" +
          details["email"]["value"] +
          "&password=" +
          details["password"]["value"] +
          "&code=" +
          details["otp"]["value"],
        {
          method: "POST",
        }
      )
        .then((response) => response.json())
        .then((resData) => {
          if (resData["data"]["status"] === 200) {
            login();
          } else {
            setSubmited(1);
          }
        });
    }
  }

  //render
  return (
    <div className="sign-in">
      <Favicon url={!props.loader ? props.icon : gif} />
      {props.loader === 0 ? (
        ""
      ) : (
        <div className="load-data">
          <i className="loader grad-text fa fa-circle-o-notch fa-spin"></i>
        </div>
      )}
      <div className="sign-form">
        <img src={logo} />
        {props.width > 1200 ? (
          <h2 className="grad-text">בומים של מבצעים כל 24 שעות</h2>
        ) : (
          ""
        )}
        <div className="fields">
          <p>
            <span
              style={{ fontWeight: action === "register" ? "700" : "" }}
              onClick={() => {
                setSubmited(0);
                setAction("register");
              }}
            >
              הירשמו |{" "}
            </span>
            <span
              style={{ fontWeight: action === "login" ? "700" : "" }}
              onClick={() => {
                setSubmited(0);
                setAction("login");
              }}
            >
              התחברו
            </span>
          </p>

          {action == "register" ? (
            <label className="req">
              <span>השם שלך</span>
              {submited === 1 && !details["name"]["exist"] ? (
                <span className="alert">יש למלא שם</span>
              ) : (
                ""
              )}
            </label>
          ) : (
            ""
          )}
          {action == "register" ? (
            <input
              placeholder="שם מלא"
              onChange={(e) => {
                const val = e.target.value;
                setDetails((prevState) => {
                  return {
                    ...prevState,
                    name: { value: val, exist: val.length > 0 ? 1 : 0 },
                  };
                });
              }}
            />
          ) : (
            ""
          )}
          {action === "recover" ? (
            ""
          ) : (
            <label className="req">
              <span>אימייל</span>
              {submited === 1 ? (
                <span className="alert">
                  {!details["email"]["exist"]
                    ? "יש להכניס את האימייל"
                    : emailExist === 0
                    ? "זהו לא אימייל תקין"
                    : action === "login" && emailExist === 2
                    ? "לא מצאנו אימייל כזה"
                    : action === "register" && emailExist === 1
                    ? "האימייל כבר קיים"
                    : ""}
                </span>
              ) : (
                ""
              )}
              {details["email"]["validate"] && !emailExist ? (
                <i className="load-input grad-text fa fa-circle-o-notch fa-spin"></i>
              ) : (details["email"]["validate"] &&
                  emailExist === 1 &&
                  action === "login") ||
                (emailExist === 2 && action === "register") ? (
                <i className="load-input grad-text fa fa-check"></i>
              ) : (details["email"]["validate"] &&
                  emailExist === 1 &&
                  action === "register") ||
                (emailExist === 2 && action === "login") ? (
                <i className="load-input grad-text fa fa-times"></i>
              ) : (
                ""
              )}
            </label>
          )}
          {action === "recover" ? (
            ""
          ) : (
            <input
              disabled={action === "recover" ? true : false}
              value={details["email"]["value"]}
              onChange={(e) => {
                setEmailExist(0);
                const val = e.target.value;
                setDetails((prevState) => {
                  return {
                    ...prevState,
                    email: {
                      value: val,
                      exist: val.length > 0 ? 1 : 0,
                      validate: validateEmail(val),
                    },
                  };
                });
              }}
              placeholder="מלאו כאן את כתובת המייל שלכם"
            />
          )}

          {/* קוד אימות */}

          {action === "recover" ? (
            <label className="req">
              <span>קוד אימות שנשלח למייל</span>
              {submited === 1 ? (
                <span className="alert">
                  {!details["otp"]["exist"]
                    ? "יש להכניס את קוד האימות במלואו"
                    : ""}
                </span>
              ) : (
                ""
              )}
            </label>
          ) : (
            ""
          )}
          {action === "recover" ? (
            <input
              value={details["otp"]["value"]}
              onChange={(e) => {
                const val = e.target.value;
                setDetails((prevState) => {
                  return {
                    ...prevState,
                    otp: {
                      value: val,
                      exist: val.length > 3 ? 1 : 0,
                    },
                  };
                });
              }}
              placeholder="מלאו את הקוד שקיבלתם"
            />
          ) : (
            ""
          )}

          <label className="req">
            <span>{action === "login" ? "סיסמה" : "צרו סיסמה חדשה"}</span>
            {action === "login" && submited === 2 ? (
              <span className="alert">הסיסמה אינה נכונה</span>
            ) : (
              ""
            )}
            {submited === 1 ? (
              <span className="alert">
                {!details["password"]["exist"] && action !== "login"
                  ? "סיסמה צריכה להכיל לפחות 7 תווים"
                  : !details["password"]["validate"]
                  ? "סיסמה חייבת להכיל מספרים ואותיות באנגלית בלבד"
                  : ""}
              </span>
            ) : (
              ""
            )}
            {
              <i
                style={{
                  background:
                    "-webkit-linear-gradient(0deg, #5A45FF " +
                    details["password"]["value"].length * 15 +
                    "%, #bdb2b2 0%)",
                }}
                className="load-input strangth fa fa-circle"
              ></i>
            }
          </label>
          <input
            placeholder="רשמו כאן את הסיסמה שבחרתם"
            type="password"
            value={details["password"]["value"]}
            onChange={(e) => {
              const val = e.target.value;
              setDetails((prevState) => {
                return {
                  ...prevState,
                  password: {
                    value: val,
                    exist: val.length > 6 ? 1 : 0,
                    validate: /^[a-z0-9]*$/i.test(val) ? 1 : 0,
                  },
                };
              });
            }}
          />
          {action === "login" ? (
            <span onClick={() => recover()} className="recover">
              שכחתי סיסמה
            </span>
          ) : (
            ""
          )}
          {action === "register" ? (
            <div className="accept">
              <span
                onClick={() => setTerms(terms * -1)}
                style={{
                  background: terms === -1 ? "#2077FF" : "",
                  borderColor: submited === 1 && terms === 1 ? "#F32771" : "",
                }}
                className="check"
              >
                {terms === 1 ? "" : <i className="fa fa-check"></i>}
              </span>
              <span className="check-label" >
                קראתי ואני מאשר/ת את <a target="_blank" href="https://boombuy.co.il/terms#">תנאי השימוש</a>
              </span>
            </div>
          ) : (
            ""
          )}
          {action === "register" ? (
            <div className="accept red">
              <span
                className="check"
                onClick={() => setClub(club * -1)}
                style={{ background: club === -1 ? "#2077FF" : "" }}
              >
                {club === 1 ? "" : <i className="fa fa-check"></i>}
              </span>
              <span className="check-label">
                צרפו אותי גם למועדון הלקוחות של BoomBuy
              </span>
            </div>
            
          ) : (
            ""
          )}


          {action === "register" && club === -1 ? (
            <div className="club-form fields">
              <label className="req">
                <span>
                  טלפון
                  <span className="input-des">
                    {" "}
                    | נעדכן אתכם בסמס בבומים חמים
                  </span>
                </span>
                {submited === 1 && !details["phone"]["validate"] ? (
                  <span className="alert">מספר הטלפון לא תקין</span>
                ) : (
                  ""
                )}
                {details["phone"]["validate"] === 1 ? (
                  <i
                    onClick={() =>
                      (details["email"] = {
                        value: "",
                        exist: 0,
                        validate: false,
                      })
                    }
                    className="load-input grad-text fa fa-check"
                  ></i>
                ) : (
                  ""
                )}
              </label>
              <input
                placeholder="מלאו כאן את מספר הטלפון שלכם"
                onChange={(e) => {
                  const val = e.target.value;
                  setDetails((prevState) => {
                    return {
                      ...prevState,
                      phone: {
                        value: val,
                        exist: val.length > 0 ? 1 : 0,
                        validate: /^05\d([-]{0,1})\d{7}$/.test(val) ? 1 : 0,
                      },
                    };
                  });
                }}
              />

              <label>
                <span>
                  תאריך לידה
                  <span className="input-des">
                    {" "}
                    | נשלח לכם הטבות שוות ביומולדת שלכם
                  </span>
                </span>
                {submited === 1 &&
                (!details["date"]["validate"] ||
                  !details["year"]["validate"] ||
                  !details["month"]["validate"]) ? (
                  <span className="alert">התאריך אינו חוקי</span>
                ) : (
                  ""
                )}
              </label>
              <div className="birth">
                <input
                  min="1"
                  max="31"
                  type="number"
                  placeholder="יום"
                  onChange={(e) => {
                    const val = e.target.value;
                    setDetails((prevState) => {
                      return {
                        ...prevState,
                        date: {
                          value: val,
                          exist: val.length > 0 ? 1 : 0,
                          validate: val > 0 && val < 32 ? 1 : 0,
                        },
                      };
                    });
                  }}
                />
                <input
                  min="1"
                  max="12"
                  type="number"
                  placeholder="חודש"
                  onChange={(e) => {
                    const val = e.target.value;
                    setDetails((prevState) => {
                      return {
                        ...prevState,
                        month: {
                          value: val,
                          exist: val.length > 0 ? 1 : 0,
                          validate: val > 0 && val < 13 ? 1 : 0,
                        },
                      };
                    });
                  }}
                />
                <input
                  min="1900"
                  max="2020"
                  type="number"
                  placeholder="שנה"
                  onChange={(e) => {
                    const val = e.target.value;
                    setDetails((prevState) => {
                      return {
                        ...prevState,
                        year: {
                          value: val,
                          exist: val.length > 0 ? 1 : 0,
                          validate: val > 1899 && val < 2020 ? 1 : 0,
                        },
                      };
                    });
                  }}
                />
              </div>

              <label>
                <span>
                  עיר
                  <span className="input-des">
                    {" "}
                    | נעדכן אתכם בבומים שהכי קרובים אליכם
                  </span>
                </span>
                {submited === 1 && !details["address"]["exist"] ? (
                  <span className="alert">יש להכניס את הכתובת</span>
                ) : (
                  ""
                )}
              </label>


              <input
                placeholder="רשמו כאן את עיר המגורים שלכם"
                onChange={(e) => {
                  const val = e.target.value;
                  setDetails((prevState) => {
                    return {
                      ...prevState,
                      address: { value: val, exist: val.length > 0 ? 1 : 0 },
                    };
                  });
                }}
              />
              {action === "register" ? (
            <div className="accept ">
             
              <span
                className="check"
                onClick={() => {setMale(male * -1); serGender("male")}}
                style={{ background: male === -1 ? "#2077FF" : "" }}
              >
                {male === 1 ? "" : <i className="fa fa-check"></i>}
              </span>
              <span style={{paddingLeft:"10px", }}>
                זכר
              </span>
              <span
                className="check"
                onClick={() => {setFemale(female * -1) ;serGender("female")}}
                style={{ background: female === -1 ? "#2077FF" : "" }}
              >
                {female === 1 ? "" : <i className="fa fa-check"></i>}
              </span>
              <span style={{paddingLeft:"10px"}}>
                נקבה
              </span>
            </div>
            
          ) : (
            ""
          )}
            </div>

          ) : (
            ""
          )}



          <button
            className="send"
            onClick={
              action === "login" && emailExist && details["password"]["exist"]
                ? () => login()
                : action === "register"
                ? () => register()
                : action === "recover"
                ? () => doRecover()
                : () => setSubmited(1)
            }
          >
            {action === "login" ? "התחברו" : "הירשמו"}
          </button>
          <p className="consent">
            בהרשמה אתם מאשרים שליחת בומים חמים וסודיים אליכם למייל ובסמס
          </p>
          <p className="consent">
יש לכם בעיה בהרשמה אנא פנו אלינו:
 <a target="blank" href={"https://api.whatsapp.com/send?phone=" +  props.options.phon_number_whatapp}>לווצאפ</a>
          </p>
        </div>
      </div>
      {props.width > 1200 ? (
        <div className="sign-img">
          <img src={illustration} />
        </div>
      ) : (
        ""
      )}
      <button className="back" onClick={props.goBack}>
        חזרה
      </button>
    </div>
  );
}

export default SignIn;

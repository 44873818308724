//assets
import my_video from "./assets/video.mov";
import my_back from "./assets/back-min.webp";
import flag from "./assets/ticket.svg";

//component
function Main(props) {
  //render
  return (
    <div
      className="hero"
      style={{
        backgroundImage: "url(" + my_back + ")",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      {props.width > 900 ? (
        <video id="myVid" autoPlay loop muted>
          <source src={my_video} type="video/mp4" />
        </video>
      ) : (
        ""
      )}
      <div className="hero-content">
        <h5>{props.options["website_name"]}</h5>
        <h2>{props.options["main_title"]}</h2>
        <p
          dangerouslySetInnerHTML={{ __html: props.options["secondery_title"] }}
        ></p>
        <h2>{props.options["another_title"]}</h2>
        <img className="flag" src={flag} />
      </div>
    </div>
  );
}

export default Main;

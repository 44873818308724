//react hooks
import React, { useState, useEffect } from "react";

//components
import OffProduct from "./OffProduct.js";
import Product from "./Product.js";

//liberis
import ScrollContainer from "react-indiana-drag-scroll";

//component
function Loop(props) {
  //set states
  const [loop, countLoop] = useState(0);
  const [grab, setGrab] = useState(0);
  const [grads, setGrads] = useState({});
  const [userProdact, setUserProdact] = useState(0);
  const gradsOptions = [
    "linear-gradient(190deg, #0ACCA5 0%, #6AA6FF 100%)",
    "linear-gradient(190deg, #EB42D0 0%, #6A3BEB 100%)",
    "linear-gradient(190deg, #F44E8A 0%, #FF4521 100%)",
    "linear-gradient(190deg, #4CE0EB 0%, #5EB921 100%)",
    "linear-gradient(189deg, #37AEE2 0%, #5A45FF 100%)",
  ];
    const [reminderUser, setReminderUser] = useState(0);
    const [useFuncrionnn , setUseFuncrionnn]= useState();
  useEffect(async() => {
    props.countLoops();
    if(props.design === "future" && useFuncrionnn !== 1){
    setUseFuncrionnn(1);
     if(props.user !== undefined ){
      if(props.user["data"] !== undefined){
        const _res2 = await  fetch(
          "https://manage.boombuy.co.il/wp-json/react/v1/get_user_remarcety?email=" + 
          props.user["data"]["user_email"],
          {
            method: "GET",
          }
        )
        .then((response) =>  response.json())
        .then((resData) => {
          // console.log(resData,"resDataresDataresData");
            var people = JSON.parse(resData);
            setReminderUser(people.customers[0].external_tags);
        })}   
     }
    }
  });



  useEffect(() => {
    let counter = 0;
    let currentGrads = {};
    for (let index = 0; index < props.data.length; index++) {

      if (
        props.design === "future" &&
        props.data[index]["time_to_start"] - props.time > 0 &&
        props.data[index]["time_to_stop"] - props.time >= 0
      ) {
        currentGrads[props.data[index]["id"]] = gradsOptions[counter];
        if (counter === gradsOptions.length - 1) {
          counter = 0;
        } else {
          counter++;
        }
      }
    }
    setGrads(currentGrads);
  }, []);
  //render
  return (
    <div style={{ display: loop > 0 ? "" : "none" }} className="product-loop">
      <h3 className="loop-title">{props.title}</h3>
      <ScrollContainer
        id="loop"
        className="the-loop"
        onStartScroll={() => setGrab(1)}
        onEndScroll={() => setGrab(0)}
        style={{ cursor: grab ? "grabbing" : "grab" }}
      >
        {props.data.map((single, index) => {
          return props.data[index]["show-in-loops"] === "false" ||
            index === props.todaysCoupon ||
            (props.page === "singleProduct" &&
              index === props.currentCoupon) ? (
            ""
          ) : props.design === "active" &&
            props.data[index]["time_to_stop"] - props.time >= 0 &&
            props.data[index]["time_to_start"] - props.time < 0 ? (
            <Product
              key={index}
              user={props.user}
              time={props.time}
              render={"loop"}
              productData={props.data[index]}
              changePage_andMain={() => {
                props.changeMain(index);
                props.changePage(props.data[index]["id"]);
              
              }}
              countLoop={() => countLoop(1)}
            />
          ) : (props.design === "past" &&
              props.data[index]["time_to_stop"] - props.time < 0) ||
            (props.design === "future" &&
              props.data[index]["time_to_start"] - props.time > 0 &&
              props.data[index]["time_to_stop"] - props.time >= 0) ? (
              <OffProduct
                status={props.design}
                grad={grads[props.data[index]["id"]]}
                key={index}
                reminderUser = {reminderUser}
                productData={props.data[index]}
                countLoop={() => countLoop(1)}
                loop={loop}
                userProdact ={userProdact}
                user={props.user}
                goToregister={props.goToregister}
            />
          ) : (
            ""
          );
        })}
      </ScrollContainer>
    </div>
  );
}

export default Loop;

//assets
import logo from "./assets/logo.svg";
import facebook from "./assets/facebook.svg";
import tiktok from "./assets/tik tok.svg";
import instegram from "./assets/instagram.svg";
import logos from "./assets/salexpress__new_logoFINAL_(2).webp"
//component
function Footer(props) {
  //render
  return (
    <div>
      <div className="footer">
        <img className="logo" src={logo} />
        <div className="social">
          <a href="https://vm.tiktok.com/ZSJ8W2G2U/" target="blank">
            <img src={tiktok} />
          </a>
          <a
            href="https://instagram.com/boombuy_il?igshid=oe91acfodcus"
            target="blank"
          >
            <img src={instegram} />
          </a>
          <a href="https://www.facebook.com/BoomBuy.co.il/" target="blank">
            <img src={facebook} />
          </a>
        </div>
        <div className="legal">
          <a href="#" onClick={() => props.changePage("terms")}>
            תנאי שימוש
          </a>
          <span className="vl" />
          <a href="#" onClick={() => props.changePage("privacy")}>
            פרטיות
          </a>
          <hr />
        </div>
        <a className="copyrights">כל הזכויות שמורות לבום ביי</a>
      </div>
      <div className="credit">
        <h4>
          נוצר על ידי
        
        </h4>
        <a className="flex" href="http://salexpress.co.il/">
          <img
          className="logos"
          src={logos}
        />
        </a>
        
      </div>
    </div>
  );
}

export default Footer;

//react hooks
import React, { useState } from "react";
import Favicon from "react-favicon";

//assets
import logo from "./assets/logo-colored.svg";
import lavan from './assets/NightSale.svg'
import banerlogin from "./assets/banerlogin-lavan.svg";
import "font-awesome/css/font-awesome.min.css";
import gif from "./assets/load.gif";
import cocav from './assets/cocav-lavan.svg'


//component
function SignInSpecial(props) {
  const [page, setPage] = useState(
    window.location.pathname === "/sign_in"
      ? "sign_in"
      : window.location.pathname
          .substring(1)
          .replace("/", "")
          .match(/^[0-9]+$/)
      ? "singleProduct"
      : window.location.pathname === "/privacy"
      ? "privacy"
      : window.location.pathname === "/terms"
      ? "terms"
      : window.location.pathname === "/#page"
      ? "page"
      :window.location.pathname === "/categoris"
      ? "categoris"
      : window.location.pathname === "/signIn/special"
      ? "signIn/special"
      : window.location.pathname === "/login/specile"
      ?"login/specile"
      :"home"    
  );
  const [prevPage, setPrevPage] = useState("home");
  //set state
  const [action, setAction] = useState("register");
  const [emailExist, setEmailExist] = useState(0);
  const [submited, setSubmited] = useState(0);
  const [terms, setTerms] = useState(1);
    const [loader, toggleLoader] = useState(0);
  const [termss, setTermss] = useState(1);
  const [club, setClub] = useState(1);
  const [details, setDetails] = useState({
    name: { value: "", exist: 0 },
    nameOrgen: { value: "", exist: 0 },
    email: { value: "", exist: 0, validate: 0 },
    password: { value: "", exist: 0, validate: 0 },
    phone: { value: "", exist: 0, validate: 0 },
    date: { value: "", exist: 0, validate: 0 },
    month: { value: "", exist: 0, validate: 0 },
    year: { value: "", exist: 0, validate: 0 },
    address: { value: "", exist: 0, validate: 0 },
    otp: { value: "", exist: 0 },
  });

  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let validate = re.test(String(email).toLowerCase());
    if (validate) {
      validateEmailExist(email);
    }
    return validate;
  }

  function validateEmailExist(email) {
    fetch(
      "https://manage.boombuy.co.il/wp-json/react/v1/users1?user_email=" +
        email,
      {
        method: "GET",
      }
    )
      .then((response) => response.json())
      .then((resData) => {
        setTimeout(function () {
          setEmailExist(resData ? 1 : 2);
        }, 250);
      });
  }
    function loadPage() {
    toggleLoader(1);
    setTimeout(function () {
      toggleLoader(0);
    }, 400);
  }

  function validateReg() {
    if (
      emailExist === 1 ||
      !details["email"]["validate"] ||
      !details["password"]["validate"] ||
      !details["name"]["exist"] ||
      terms === 1 ||
      (club === -1 && !details["phone"]["validate"])
    ) {
      return false;
    } else {
      return true;
    }
  }

  //register user and then log them in
  function register() {
    fetch(
        "https://manage.boombuy.co.il/wp-json/react/v1/register_user_lavan/?username=" +
          details["email"]["value"] +
          "&user_email=" +
          details["email"]["value"] +
          "&password=" +
          details["password"]["value"] +
          "&nickname=" +
          details["name"]["value"] +
          "&phone=" +
          details["phone"]["value"] +
          "&address=" +
          details["address"]["value"] +
          "&date=" +
          details["year"]["value"] +
          "-" +
          details["month"]["value"] +
          "-" +
          details["date"]["value"],
        {
          method: "POST",
        }
      )
        .then(function (response) {
          if (response.status !== 200) {
            alert("תקלה מספר" + response.status);
          }
          //  alert("״תודה על ההרשמה אנחנו נעדכן אתכם כשהמכירה תיפתח״");
            fetch(
              "https://manage.boombuy.co.il/wp-json/react/v1/remarketlav?email=" +
               details["email"]["value"] +
               "&product=peoduct-" + 'special'
               
            )
              .then((response) => response.json())
              .then((resData) => {
               props.changePage("/login/specile")
               return response.json();

              });
          
        })
        .then(() => {
         
          return;
        });
    // if (!validateReg()) {
    //   setSubmited(1);
    // } else {
      
    // }
  }

  //log the user in

  function recover() {
     fetch(
        "https://manage.boombuy.co.il/wp-json/react/v1/register_user_lavan/?phone=0587105273" +
        {
          method: "GET",
        }
      )
        .then((response) => response.json())
        .then((resData) => {
          //  props.changePage("/login/specile")

        });
    // if (emailExist !== 1) {
    //   setSubmited(1);
    // } else {
        
    // }
  }

  function doRecover() {
    if (
      !details["password"]["validate"] ||
      !details["password"]["exist"] ||
      !details["otp"]["exist"] ||
      !details["password"]["exist"]
    ) {
      setSubmited(1);
    } else {
      fetch(
        "https://manage.boombuy.co.il//wp-json/bdpwr/v1/set-password/?email=" +
          details["email"]["value"] +
          "&password=" +
          details["password"]["value"] +
          "&code=" +
          details["otp"]["value"],
        {
          method: "POST",
        }
      )
        .then((response) => response.json())
        .then((resData) => {
          if (resData["data"]["status"] === 200) {
            // login();
          } else {
            setSubmited(1);
          }
        });
    }
  }

  //render
  return (
    <div className="sign-in " >
      <Favicon url={!props.loader ? props.icon : gif} />
      {props.loader === 0 ? (
        ""
      ) : (
        <div className="load-data">
          <i className="loader grad-text fa fa-circle-o-notch fa-spin"></i>
        </div>
      )}
      
      <div className="sign-form mobile-lavan-rek" style={{paddingTop: "7vw"}} >
            <img src={cocav} style={props.width > 1200? {display:"none"}: {position: "absolute",top: "0",left: "0", width: "369px", display:"inline-block"}}/>

        <img className="logo-lavan" src={logo} />
         <img className="image-night" src={lavan} />

          <div className="titel-log-lavan">הרשמה לאירוע מכירות סגור</div>
          <div className="ti-log-lavan">לילה לבן</div>
          <p className="text-log-lavan">על מנת להשתתף בלילה המכירות וקבלת לינק לאירוע יש למלא את הפרטים ולהרשם באתר boombuy </p>
        <div className="fields fields-lav">

            <label className="req">
              <span>השם שלך</span>
              {submited === 1 && !details["name"]["exist"] ? (
                <span className="alert">יש למלא שם</span>
              ) : (
                ""
              )}
            </label>
             
            <input
              placeholder="שם מלא"
              onChange={(e) => {
                const val = e.target.value;
                setDetails((prevState) => {
                  return {
                    ...prevState,
                    name: { value: val, exist: val.length > 0 ? 1 : 0 },
                  };
                });
              }}
            />
         
           <label className="req">
              <span>אימייל</span>
              {submited === 1 ? (
                <span className="alert">
                  {!details["email"]["exist"]
                    ? "יש להכניס את האימייל"
                    : emailExist === 0
                    ? "זהו לא אימייל תקין"
                    : action === "login" && emailExist === 2
                    ? "לא מצאנו אימייל כזה"
                    : action === "register" && emailExist === 1
                    ? "האימייל כבר קיים"
                    : ""}
                </span>
              ) : (
                ""
              )}
              {details["email"]["validate"] && !emailExist ? (
                <i className="load-input grad-text fa fa-circle-o-notch fa-spin"></i>
              ) : (details["email"]["validate"] &&
                  emailExist === 1 &&
                  action === "login") ||
                (emailExist === 2 && action === "register") ? (
                <i className="load-input grad-text fa fa-check"></i>
              ) : (details["email"]["validate"] &&
                  emailExist === 1 &&
                  action === "register") ||
                (emailExist === 2 && action === "login") ? (
                <i className="load-input grad-text fa fa-times"></i>
              ) : (
                ""
              )}
            </label>
             <input
              disabled={action === "recover" ? true : false}
              value={details["email"]["value"]}
              onChange={(e) => {
                setEmailExist(0);
                const val = e.target.value;
                setDetails((prevState) => {
                  return {
                    ...prevState,
                    email: {
                      value: val,
                      exist: val.length > 0 ? 1 : 0,
                      validate: validateEmail(val),
                    },
                  };
                });
              }}
              placeholder="מלאו כאן את כתובת המייל שלכם"
            />

            <label className="req">
                <span>
                  טלפון
                  <span className="input-des">
                    {" "}
                   *ישמש לזהוי הכניסה שלכם לאירוע המכירות 
                  </span>
                </span>
                {submited === 1 && !details["phone"]["validate"] ? (
                  <span className="alert">מספר הטלפון לא תקין</span>
                ) : (
                  ""
                )}
                {details["phone"]["validate"] === 1 ? (
                  <i
                    onClick={() =>
                      (details["email"] = {
                        value: "",
                        exist: 0,
                        validate: false,
                      })
                    }
                    className="load-input grad-text fa fa-check"
                  ></i>
                ) : (
                  ""
                )}
              </label>
              <input
                placeholder="מלאו כאן את מספר הטלפון שלכם"
                onChange={(e) => {
                  const val = e.target.value;
                  setDetails((prevState) => {
                    return {
                      ...prevState,
                      phone: {
                        value: val,
                        exist: val.length > 0 ? 1 : 0,
                        validate: /^05\d([-]{0,1})\d{7}$/.test(val) ? 1 : 0,
                      },
                    };
                  });
                }}
              />
            <label className="req">
              <span>קוד ארגון</span>
              {submited === 1 && !details["nameOrgen"]["exist"] ? (
                <span className="alert">האזינו כאן את קוד הארגון</span>
              ) : (
                ""
              )}
            </label>
            <input
              placeholder="האזינו כאן את קוד הארגון"
              onChange={(e) => {
                const val = e.target.value;
                setDetails((prevState) => {
                  return {
                    ...prevState,
                    nameOrgen: { value: val, exist: val.length > 0 ? 1 : 0 },
                  };
                });
              }}
            />

             <label className="req">
            <span>{action === "login" ? "סיסמה" : "צרו סיסמה חדשה"}</span>
            {action === "login" && submited === 2 ? (
              <span className="alert">הסיסמה אינה נכונה</span>
            ) : (
              ""
            )}
            {submited === 1 ? (
              <span className="alert">
                {!details["password"]["exist"] && action !== "login"
                  ? "סיסמה צריכה להכיל לפחות 7 תווים"
                  : !details["password"]["validate"]
                  ? "סיסמה חייבת להכיל מספרים ואותיות באנגלית בלבד"
                  : ""}
              </span>
            ) : (
              ""
            )}
            {
              <i
                style={{
                  background:
                    "-webkit-linear-gradient(0deg, #5A45FF " +
                    details["password"]["value"].length * 15 +
                    "%, #bdb2b2 0%)",
                }}
                className="load-input strangth fa fa-circle"
              ></i>
            }
          </label>
          <input
            placeholder="רשמו כאן את הסיסמה שבחרתם"
            type="password"
            value={details["password"]["value"]}
            onChange={(e) => {
              const val = e.target.value;
              setDetails((prevState) => {
                return {
                  ...prevState,
                  password: {
                    value: val,
                    exist: val.length > 6 ? 1 : 0,
                    validate: /^[a-z0-9]*$/i.test(val) ? 1 : 0,
                  },
                };
              });
            }}
          />
           <div className="accept">
              <span
                onClick={() => setTermss(termss * -1)}
                style={{
                  background: termss === -1 ? "#2077FF" : "",
                  borderColor: submited === 1 && termss === 1 ? "#F32771" : "",
                }}
                className="check"
              >
                {termss === 1 ? "" : <i className="fa fa-check"></i>}
              </span>
              <span className="check-label "style={props.width > 900? {color:"#000000"}: {color:"white"}}>
                  מאשרת שליחת עדכון על אירוע המכירות ועידכון על מבצעים חמים נוספים במייל  
              </span>
            </div>
          
            <div className="accept">
              <span
                onClick={() => setTerms(terms * -1)}
                style={{
                  background: terms === -1 ? "#2077FF" : "",
                  borderColor: submited === 1 && terms === 1 ? "#F32771" : "",
                }}
                className="check"
              >
                {terms === 1 ? "" : <i className="fa fa-check"></i>}
              </span>
              <span className="check-label"  style={props.width >900? {color:"#000000"}: {color:"white"}}>
                קראתי ואני מאשר/ת את <a>תנאי השימוש</a>
              </span>
            </div>
            
          <button
            className="sendl"
             onClick={ () => register()

            }
          >
           
          {action === "login" ? "התחברו" : "הירשמו"}
          </button>

          <button
                    className="sendl"
                    onClick={ () =>  props.changePage("/login/specile")

                    }
                  >
                  יש לך חשבון התחבר
                  </button>
          <p className="consent">
            בהרשמה אתם מאשרים שליחת בומים חמים וסודיים אליכם למייל ובסמס
          </p>
        </div>
      </div>
      {props.width > 1200 ? (
        <div  className="signla-img">
           <img style={{width: "50vw"}} src={banerlogin} />
         </div>
      ) : (
        ""
      )}
      {/* <button className="back" onClick={props.goBack}>
        חזרה
      </button> */}
    </div>
  );
}

export default SignInSpecial;

//react hooks
import React, { useState } from "react";

//assets
import "font-awesome/css/font-awesome.min.css";

//component
function Faq(props) {
  //convert object to array
  let object = props.settings;
  var questions = Object.keys(object).map((key) => [Number(key), object[key]]);

  //set state
  const [status, toggle] = useState();

  //render
  return (
    <div id="faq" className="faq">
      <h2 className="faq-title">שאלות נפוצות</h2>

      <div className="faq-items">
        {questions.map((single, index) => (
          <div
            className="item"
            style={{ maxHeight: status === index ? "500px" : "60px" }}
            key={index}
          >
            <span
              onClick={() => (status !== index ? toggle(index) : toggle(""))}
              className="question"
            >
              <i
                style={{
                  transform:
                    status !== index ? "rotate(0deg)" : "rotate(45deg)",
                }}
                className="show-more fa fa-plus"
              ></i>{" "}
              {single[1].question}
            </span>
            {status === index || 1 === 1 ? (
              <span
                className="answer"
                dangerouslySetInnerHTML={{ __html: single[1].answer }}
              ></span>
            ) : (
              ""
            )}
          </div>
        ))}
      </div>

      <div className="more-q">
        <h5>לשאלות נוספות</h5>
        <a href="mailto:contact@BoomBuy.co.il">contact@BoomBuy.co.il</a>
      </div>
    </div>
  );
}

export default Faq;

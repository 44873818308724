//react hooks
import React, { useState, useEffect } from "react";
//components
import Header from "./Header.js";
import Main from "./Main.js";
import Product from "./Product.js";
import Banner from "./Banner.js";
import Loop from "./Loop.js";
import Faq from "./Faq.js";
import Categoris from "./Categoris";
import Category from "./Category";
import Footer from "./Footer.js";
import SignIn from "./SignIn.js";
import LoginSpecile from "./LoginSpecile.js"
import SignInSpecial from "./SignInSpecial.js";
import Humburger from "./Hamburger.js";
import ContentPage from "./ContentPage.js";
import Favicon from "react-favicon";
import Popup from 'reactjs-popup';
import ReactDOM from 'react-dom';
import {Helmet} from "react-helmet";

//assets
import "./App.css";
import "font-awesome/css/font-awesome.min.css";
import gif from "./assets/load.gif";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import { LazyLoadImage } from 'react-lazy-load-image-component';


function App() {
  // set states
    const pathrrr = window.location.pathname;

  const [page, setPage] = useState(
    window.location.pathname === "/sign_in"
      ? "sign_in"
      : window.location.pathname
          .substring(1)
          .replace("/", "")
          .match(/^[0-9]+$/)
      ? "singleProduct"
      : window.location.pathname === "/privacy"
      ? "privacy"
      : window.location.pathname === "/terms"
      ? "terms"
      : window.location.pathname === "/#page"
      ? "page"
      :window.location.pathname === "/blackFriday"
      ? "blackFriday"
      : window.location.pathname === "/signIn/special"
      ? "signIn/special"
      : window.location.pathname === "/login/specile"
      ?"login/specile"
      : window.location.pathname.includes("category")
      ? "category"
      :"home"    
  );
  const [prevPage, setPrevPage] = useState("home");
  const [products, setProducts] = useState([{}]);
  const [categoryData, setCategoryData] = useState([{}]);
  const [currentCoupon, setCurrentCoupon] = useState(0);
  const [categoryId, setCategoryId] = useState(0);
  const [options, setOptions] = useState([{}]);
  const [loading, setLoad] = useState(0);
  const [time, setTime] = useState(Math.floor(+new Date() / 1000));
  const [img, setImg] = useState(0);
  const [user, setUser] = useState({});
  const [loader, toggleLoader] = useState(0);
  const [path, setPath] = useState(window.location.pathname);
  const [width, setWidth] = useState(window.innerWidth);
  const [hamburger, setHamburger] = useState(1);
  const [loops, countLoops] = useState(0);

  useEffect(() => {
    const _id = options.categoryid;
    fetch(`https://manage.boombuy.co.il/wp-json/react/v1/products/category/${_id}`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((resData) => {
        setCategoryData(resData);
      })
  },[categoryData])

 useEffect(() => {

      fetch("https://manage.boombuy.co.il/wp-json/react/v1/products_json", {
      method: "GET",
    })
      .then((response) => response.json())
      .then((resData) => {
        // sessionStorage.setItem('products', JSON.stringify(resData))
        setProducts(resData);

        setCurrentCoupon(getIndex(resData, window.location.pathname));

      });


    return fetch("https://manage.boombuy.co.il/wp-json/react/v1/options", {
      method: "GET",
    })
      .then((response) => response.json())
      .then((resData) => {
        console.log(resData,"resDataresDataresData");
        setOptions(resData);
        setTimeout(function () {
          setLoad(1);
        }, 250);
      });
        
  }, []);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);



  //get user by coocies
  useEffect(() => {
    
    const getCookieValue = (name) =>
      document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")?.pop() ||
      "";
    let userId = getCookieValue("user_id");
    if (userId) {
      fetch(
        "https://manage.boombuy.co.il/wp-json/react/v1/users?user_id=" + userId,
        {
          method: "GET",
        }
      )
        .then((response) => response.json())
        .then((resData) => {
          setUser(resData);
          if (userId && page === "sign_in") {
            setPage("home");
          }
        });
        

    }
     
            
  }, []);

  

  //set current time
  useEffect(() => {
    const secTimer = setInterval(() => {
      setTime(new Date() / 1000);
    }, 1000);

    return () => clearInterval(secTimer);
  }, []);

  //gets product id an returns the product index
  function getIndex(resData, productId) {
    productId = Number(window.location.pathname.substring(1).replace("/", ""));
    let count = 0;
    for (let i = 0; i < resData.length; i++) {
      if (resData[i]["id"] === productId) {
        count++;
        return i;
      }
    }
    if (page === "singleProduct" && count === 0) {
      setPage("home");
    }
  }
  

  //load page animation
  function loadPage() {
    toggleLoader(1);
    setTimeout(function () {
      toggleLoader(0);
    }, 400);
  }

  // get the index of relevent coupon
  function get_todays_coupon() {
    let id, main;
    let list = options["main-coupon"];
    let newDate = new Date();
    let hour = newDate.getHours();
    let minute = newDate.getMinutes();

    // checks if to show todays or yesterdays coupon
    let changeTime = options["time-of-change"].split(":");
    if (
      hour < changeTime[0] ||
      (hour === changeTime[0] && minute < changeTime[1])
    ) {
      newDate.setDate(newDate.getDate() - 1);
    }
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    for (let i in list) {
      let the_date = list[i]["date"].split("-");
      if (
        Number(the_date[0]) === year &&
        Number(the_date[1]) === month &&
        Number(the_date[2]) === date
      ) {
        id = list[i]["selected-coupon"];
        break;
      }
    }
    if (id > 0) {
      //if tere is a todays coupon
      for (let i = 0; i < products.length; i++) {
        if (products[i]["id"] == id) {
          main = i;
        }
      }
    }
    return main;
  }

  //render loading page
  if (loading === 0) {
    return (

      <div className="load-page">
        <Favicon url={gif} />
        <i className="loader grad-text fa fa-circle-o-notch fa-spin"></i>
      </div>
    );
  }



  //render sign in page
  if (page === "sign_in") {
    return (
      <SignIn
        icon={options["site-icon"]}
        goBack={() => {
          loadPage();
          window.history.pushState({}, "", "/");
          setPage(prevPage);
          setPath("/");
        }}
        setNewUser={(data) => {
          setUser(data);
          prevPage === "home"
            ? window.history.pushState({}, "", "/" + "home")
            :prevPage === "category"
            ?  window.history.pushState({}, "", `${path}`)
            : window.history.pushState(
                {},
                "",
                "/" + products[currentCoupon]["id"]
              );
          setPage(prevPage);
          toggleLoader(0);
        }}
        askData={() => toggleLoader(1)}
        endLoad={() => toggleLoader(0)}
        loader={loader}
        width={width}
        options={options}
      />
    );
  }

  

  if (page === "login/specile") {
    return (
      <LoginSpecile
        icon={options["site-icon"]}
        goBack={() => {
          loadPage();
          window.history.pushState({}, "", "/");
          setPage(prevPage);
          setPath("/");
        }}
        setNewUser={(data) => {
          setUser(data);
          loadPage();
          setPrevPage(page);
                setPage("blackFriday");
                window.history.pushState({}, "", "/blackFriday");
          toggleLoader(0);
        }}
        askData={() => toggleLoader(1)}
        endLoad={() => toggleLoader(0)}
        loader={loader}
        width={width}
        options={options}
      />
    );
  }

  if (page === "signIn/special") {
    return (
      <SignInSpecial
        icon={options["site-icon"]}
        goBack={() => {
          loadPage();
          window.history.pushState({}, "", "/");
          setPage(prevPage);
          setPath("/");
        }}
        setNewUser={(data) => {
          setUser(data);
          prevPage === "home"
            ? window.history.pushState({}, "", "/" + "signIn/special")
            : window.history.pushState(
                {},
                "",
                "/" + products[currentCoupon]["id"]
              );
          setPage(prevPage);
          toggleLoader(0);
        }}
        askData={() => toggleLoader(1)}
        endLoad={() => toggleLoader(0)}
        loader={loader}
        width={width}
        options={options}
        changePage={() => {
                loadPage();
                setPage("login/specile");
                window.history.pushState({}, "", "/login/specile");
        }}
      />
    );
  }

  if (page === "category") {
    const pas = window.location.pathname;
    var substring = pas.replace(/[^0-9]/g,'');
        const titel = options[`titel_${substring}`];
        const text = options[`text_${substring}`];
        const image = options[`imag_${substring}`]
        console.log(titel,"titeltitel");
           return (
            <Category
              goBack={() => {
                loadPage();
                window.history.pushState({}, "", "/");
                setPage(prevPage);
                setPath("/");
              }}
              width={width}
              get_fun ={get_todays_coupon()}
              time = {time}
              user = {user}
              titelSpecile={titel}
              textSpecile={text}
              image={image}
              showMesseg={options.messegcategory}
              countLoops={() => countLoops(1)}
              options = {options}
              id= {substring}
              page = {page}
              goToregister={() => {
                        loadPage();
                        setPrevPage('category');
                        setPage("sign_in");
                        window.history.pushState({}, "", "/sign_in");
              }}
              goToCategoris={() => {
                loadPage();
                setPrevPage(page);
                console.log(substring,"substringsubstring");
                setCategoryId(substring);
                setPage("category");
                window.history.pushState({}, "", "/blackFriday");
              }}
              toggleHamburger={() => setHamburger(hamburger * -1)}
              removeUser={() => {
                  setUser({});
                  document.cookie =
                    "user_id=; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
                  loadPage();
                }}
                changePage={() => {
                setPrevPage("category");
                loadPage();
                setPage("sign_in");
                window.history.pushState({}, "", "/sign_in");
              }}
            />

    
    );
  }


  if (page === "blackFriday") {
    const pas = window.location.pathname;
    const substring = pas.slice(-2);

           return (
            <Categoris
              goBack={() => {
                loadPage();
                window.history.pushState({}, "", "/");
                setPage(prevPage);
                setPath("/");
              }}
              width={width}
              get_fun ={get_todays_coupon()}
              time = {time}
              user = {user}
              titelSpecile={options.titelspecile}
              textSpecile={options.textspecile}
              image={options.imageheaderweight}
              showMesseg={options.messegcategory}
              countLoops={() => countLoops(1)}
              products ={categoryData}
              options = {options}
              page = {page}
              goToregister={() => {
                        loadPage();
                        setPrevPage(page);
                        setPage("sign_in");
                        window.history.pushState({}, "", "/sign_in");
              }}
              goToCategoris={() => {
                loadPage();
                setPrevPage(page);
                setPage("blackFriday");
                window.history.pushState({}, "", "/blackFriday");
              }}
              toggleHamburger={() => setHamburger(hamburger * -1)}
              removeUser={() => {
                  setUser({});
                  document.cookie =
                    "user_id=; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
                  loadPage();
                }}
                changePage={() => {
                loadPage();
                setPage("sign_in");
                window.history.pushState({}, "", "/sign_in");
              }}
            />
    
    );
  }

  if (page === "terms" || page === "privacy") {
    return (
      <ContentPage
        title={
          page === "terms" ? options["terms-title"] : options["privacy-title"]
        }
        content={
          page === "terms"
            ? options["terms-content"]
            : options["privacy-content"]
        }
        goBack={() => {
          loadPage();
          window.history.pushState({}, "", "/");
          setPage(prevPage);
          setPath("/");
        }}
      />
    );
  }


  // render main page
  return (
    <div id="app-main" className="App" >
    
      <Favicon url={!loader ? options["site-icon"] : gif} />
      {loader === 0 ? (
        ""
      ) : (
        <div className="load-data">
          <i className="loader grad-text fa fa-circle-o-notch fa-spin"></i>
        </div>
      )}
      {hamburger < 1 ? (
        <Humburger
         showBooten={options.bottennight}
        name_catgory={options.name_catgory}
        showMesseg={options.messegcategory}
          removeUser={() => {
            setUser({});
            document.cookie =
              "user_id=; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
            loadPage();
          }}
          goToCategoris={() => {
            const _id = options.categoryid;
             fetch(`https://manage.boombuy.co.il/wp-json/react/v1/products/category/${_id}`, {
               method: "GET",
             })
               .then((response) => response.json())
               .then((resData) => {
                 setCategoryData(resData);
                  loadPage();
            setPrevPage(page);
            setPage("blackFriday");
            window.history.pushState({}, "", "/blackFriday");
 
            });
           }}
          goToRegister={() => {
            loadPage();
            setPrevPage(page);
            setPage("sign_in");
            window.history.pushState({}, "", "/sign_in");
          }}
          toggleHamburger={() => setHamburger(hamburger * -1)}
          user={user}
        />
      ) : (
        ""
      )}
      <Header
        width={width}
        showBooten={options.bottennight}
        name_catgory={options.name_catgory}
        showMesseg={options.messegcategory}
        goToRegister={() => {
          loadPage();
          setPrevPage(page);
          setPage("sign_in");
          window.history.pushState({}, "", "/sign_in");
        }}
        goToCategoris={() => {
           const _id = options.categoryid;
            fetch(`https://manage.boombuy.co.il/wp-json/react/v1/products/category/${_id}`, {
              method: "GET",
            })
              .then((response) => response.json())
              .then((resData) => {
                setCategoryData(resData);
                 loadPage();
          setPrevPage(page);
          setPage("blackFriday");
          window.history.pushState({}, "", "/blackFriday");

          });
        }}
        changePage={() => {
          loadPage();
          setPage("home");
          window.history.pushState({}, "", "/");
        }}
        toggleHamburger={() => setHamburger(hamburger * -1)}
        settings={options}
        userData={user}
        page={page}
        removeUser={() => {
          setUser({});
          document.cookie = "user_id=; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
          loadPage();
        }}
      />
      <div className="content" style={{justifyContent:"center"}}>
        {page === "home" ? <Main width={width} options={options} /> : ""}
        {!products[get_todays_coupon()] && page === "home" ? (
          ""
        ) : (
          <div
            style={{ marginTop: page === "home" && width > 900 ? "-15vw" : "" }}
            id="main-event"
            className="product-wrapper"
          >
            {page === "home" ? (
              <h2 className="todays-title">הבום היומי</h2>
            ) : (
              ""
            )}
            <Product
            banertop={options.banertop}
              img={img}
              playvideo = {options.playvideo}
              acoriontex = {options.acoriontex}
              acordiontitel={options.acordiontitel}
              banertopurl={options.banertop_url}
              setImg={(i) => setImg(i)}
              render={"home"}
              productData={
                page === "home"
                  ? products[get_todays_coupon()]
                  : products[currentCoupon]
              }
              changePage={(i) => {
                loadPage();
                setPrevPage(page);
                setPage(i);
                window.history.pushState({}, "", "/" + i);
              }}
              time={time}
              user={user}
              page={page}
              width={width}
            />
          </div>
        )}

        <div className="row mobile-colom" style={{marginTop:'10px', marginBottom:'10px'}}>
            <h3 className="titel-card-app">רוצים לא לפספס אף בום?</h3>   
          <a  href={page === "singleProduct" && products[currentCoupon]["link_whattapp_card"] !== "" ? `${products[currentCoupon]["link_whattapp_card"]}` : `${options.linkcard1}`} target="_blank">
          {/* <img
            src={`${options.linkurlcard1}`}
            className="card-cesher"
            style={{width: '41.5%'}}
          /> */}
          <LazyLoadImage
            src={`${options.linkurlcard1}`}
            className="card-cesher"
            style={{width: '41.5%'}}
            />
        </a>
         <a href={page === "singleProduct" && products[currentCoupon]["link_twitter_card"] !== "" ? `${products[currentCoupon]["link_twitter_card"]}` : `${options.linkcard2}`} target="_blank">
            {/* <img
              src={`${options.linkurlcard2}`}
              className="card-cesher"
              style={{width: '41.5%',}}
            /> */}
             <LazyLoadImage
                  src={`${options.linkurlcard2}`}
                  className="card-cesher"
                  style={{width: '41.5%',}}
              />
         </a>
        </div>
        {/* <Banner
          status={user}
          goToRegister={() => {
            loadPage();
            setPrevPage(page);
            setPage("sign_in");
            window.history.pushState({}, "", "/sign_in");
          }}
        /> */}
        {(page === "singleProduct" &&
          products[currentCoupon]["display-loops"] !== "true") ||
        !products[0] ? (
          ""
        ) : (
          <div   className="loops" style={{ display: loops > 0 ? "" : "none" }}>
          <a href="#page"></a>

            {options["show-active-loop"] === "true" ? (
              <Loop
                title={"בומים פעילים שאסור לפספס"}
                design={"active"}
                data={products}
                user={user}
                page={page}
                todaysCoupon={get_todays_coupon()}
                currentCoupon={currentCoupon}
                changePage={(i) => {
                  loadPage();
                  setPage("singleProduct");
                  window.history.pushState({}, "", "/" + i);
                }}
                changeMain={(i) => {
                  setCurrentCoupon(i);
                  setImg(0);
                }}
                countLoops={() => countLoops(1)}
                time={time}
              />
            ) : (
              ""
            )}
            {options["show-future-loop"] === "true" ? (
             <div id="page">
               <Loop
                title={"הרשמה מוקדמת לבומים עתידיים"}
                design={"future"}
                data={products}
                todaysCoupon={get_todays_coupon()}
                time={time}
                user={user}
                countLoops={() => countLoops(1)}
                goToregister={() => {
                  loadPage();
                  setPrevPage(page);
                  setPage("sign_in");
                  window.history.pushState({}, "", "/sign_in");
                }}
              />
             </div>
             
            ) : (
              ""
            )}
            {options["show-old-loop"] === "true" ? (
              <Loop
                title={"מצטערים, את הבומים האלו פספסת"}
                design={"past"}
                todaysCoupon={get_todays_coupon()}
                data={products}
                countLoops={() => countLoops(1)}
                time={time}
              />
            ) : (
              ""
            )}
          </div>
        )}
        <div className="flex row galery-app" style={{width: '100%', justifyContent: "center"}}>
          <a href={`${options.buner1_url}`} target="_blank">
              <LazyLoadImage
                src={`${options.buner1}`}
                className="baner-booten-mobile"
                style={{width: "27.2381vw",height: "auto",borderRadius: "10px"}}
              />

            {/* <img src={`${options.buner1}`}
               className="baner-booten-mobile"
              style={{width: "27.2381vw",height: "auto",borderRadius: "10px"}}
              /> */}
          </a>
            <a href={`${options.buner2_url}`} style={{ marginRight: "2vw"}} target="_blank">
              <LazyLoadImage
               src={`${options.buner2}`}
               className="baner-booten-mobile"
              style={{width: "27.2381vw",height: "auto",borderRadius: "10px"}}
              />
            {/* <img src={`${options.buner2}`}
               className="baner-booten-mobile"
              style={{width: "27.2381vw",height: "auto",borderRadius: "10px"}}
              /> */}
          </a>
            <a href={`${options.buner3_url}`} style={{ marginRight: "2vw"}} target="_blank">
                 <LazyLoadImage
                   src={`${options.buner3}`}
               className="baner-booten-mobile"
              style={{width: "27.2381vw",height: "auto",borderRadius: "10px"}}
              />
            {/* <img src={`${options.buner3}`}
               className="baner-booten-mobile"
              style={{width: "27.2381vw",height: "auto",borderRadius: "10px"}}
              /> */}
          </a>
        
        </div>
        <Faq settings={options["faq-list"]} />
      </div>
      <Footer
        changePage={(i) => {
          setPrevPage(page);
          setPage(i);
          window.history.pushState({}, "", "/" + i);
          loadPage();
        }}
      />

    </div>
   
  );
}


export default App;

function ContentPage(props) {
  return (
    <div className="content-page">
      <h1
        dangerouslySetInnerHTML={{ __html: props.title.replace(/\\/g, "") }}
      ></h1>
      <p
        dangerouslySetInnerHTML={{ __html: props.content.replace(/\\/g, "") }}
      ></p>
      <button className="back" onClick={props.goBack}>
        חזרה
      </button>
    </div>
  );
}

export default ContentPage;

//react hooks
import React, { useState } from "react";
import Favicon from "react-favicon";

//assets
import logo from "./assets/logo-colored.svg";
import banerlogin from "./assets/banerlogin-lavan.svg";
import "font-awesome/css/font-awesome.min.css";
import gif from "./assets/load.gif";
import lavan from './assets/NightSale.svg';
import cocav from './assets/cocav-lavan.svg'
//component
function LoginSpecile(props) {
  //set state
  const [action, setAction] = useState("register");
  const [emailExist, setEmailExist] = useState(0);
  const [submited, setSubmited] = useState(0);
  const [terms, setTerms] = useState(1);
  const [termss, setTermss] = useState(1);
  const [club, setClub] = useState(1);
  const [details, setDetails] = useState({
    name: { value: "", exist: 0 },
    email: { value: "", exist: 0, validate: 0 },
    password: { value: "", exist: 0, validate: 0 },
    phone: { value: "", exist: 0, validate: 0 },
    date: { value: "", exist: 0, validate: 0 },
    month: { value: "", exist: 0, validate: 0 },
    year: { value: "", exist: 0, validate: 0 },
    address: { value: "", exist: 0, validate: 0 },
    otp: { value: "", exist: 0 },
  });

  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let validate = re.test(String(email).toLowerCase());
    if (validate) {
      validateEmailExist(email);
    }
    return validate;
  }

  function validateEmailExist(email) {
    fetch(
      "https://manage.boombuy.co.il/wp-json/react/v1/users1?user_email=" +
        email,
      {
        method: "GET",
      }
    )
      .then((response) => response.json())
      .then((resData) => {
        setTimeout(function () {
          setEmailExist(resData ? 1 : 2);
        }, 250);
      });
  }

  function validateReg() {
    if (
      emailExist === 1 ||
      !details["email"]["validate"] ||
      !details["password"]["validate"] ||
      !details["name"]["exist"] ||
      terms === 1 ||
      (club === -1 && !details["phone"]["validate"])
    ) {
      return false;
    } else {
      return true;
    }
  }

  //register user and then log them in


  //log the user in

  function recover() {
        props.askData();
    fetch(
        "https://manage.boombuy.co.il/wp-json/react/v1/register_user_lavan/?phone="+
        details["phone"]["value"], 
        {
          method: "GET",
        }
    )
      .then((response) => response.json())
      .then((resData) => {
        if (resData["ID"]) {
          let now = new Date();
          now.setDate(now.getDate() + 7);
          setSubmited(0);
          props.setNewUser(resData);
          document.cookie =
            "user_id=" +
            resData["data"]["ID"] +
            "; expires=" +
            now.toUTCString() +
            ";";
        } else {
          setSubmited(2);
        }
        props.endLoad();
        return;
      });
    
    // if (emailExist !== 1) {
    //   setSubmited(1);
    // } else {
        
    // }
  }

 

  //render
  return (
    <div className="sign-in">
      <Favicon url={!props.loader ? props.icon : gif} />
      {props.loader === 0 ? (
        ""
      ) : (
        <div className="load-data">
          <i className="loader grad-text fa fa-circle-o-notch fa-spin"></i>
        </div>
      )}
      <div className="sign-form mobile-lavan-rek" style={{padding: "7vh"}}>
            <img src={cocav} style={props.width > 1200? {display:"none"}: {position: "absolute",top: "0",left: "0", width: "369px", display:"inline-block"}}/>
        <img className="logo-lavan" src={logo} />
        <img className="image-night" src={lavan} />

        <div className="lila-lavan-login-contener">
          <div className="titel-log-lavan">התחברו לאירוע מכירות סגור</div>
          <div className="ti-log-lavan-login">לילה לבן</div>
           <div className="fields">
            <label className="req">
                <span>
                  טלפון
                  <span className="input-des">
                    {" "}
                  
                  </span>
                </span>
                {submited === 1 && !details["phone"]["validate"] ? (
                  <span className="alert">מספר הטלפון לא תקין</span>
                ) : (
                  ""
                )}
                {details["phone"]["validate"] === 1 ? (
                  <i
                    onClick={() =>
                      (details["email"] = {
                        value: "",
                        exist: 0,
                        validate: false,
                      })
                    }
                    className="load-input grad-text fa fa-check"
                  ></i>
                ) : (
                  ""
                )}
              </label>
              <input
                placeholder="מלאו כאן את מספר הטלפון שלכם"
                onChange={(e) => {
                  const val = e.target.value;
                  setDetails((prevState) => {
                    return {
                      ...prevState,
                      phone: {
                        value: val,
                        exist: val.length > 0 ? 1 : 0,
                        validate: /^05\d([-]{0,1})\d{7}$/.test(val) ? 1 : 0,
                      },
                    };
                  });
                }}
              />

                <button
                    className="sendl"
                    onClick={ () => recover()

                    }
                >
                התחברו
                </button>
        </div>
        </div>
      </div>
      {props.width > 1200 ? (
        <div  className="sign-img">
          <img style={{width: "50vw"}} src={banerlogin}/>
        </div>
      ) : (
        ""
      )}
      {/* <button className="back" onClick={props.goBack}>
        חזרה
      </button> */}
    </div>
  );
}

export default LoginSpecile;
